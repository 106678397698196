// "uploading" and "upload error" are client side statuses,
// It exist only to be used to display different messages.

import {OTAsset} from '../../../../store/state/ot-assets/state'
import {GuidType} from '../../../../values/generic-type-defintions'
import {AssetOptionKeyEnum} from '../location-inventory-page-context'

// in the UI, it never goes to the server or will come from the server.
export enum AssetBulkFileStatus {
    UPLOADING = 'uploading',
    UPLOAD_ERROR = 'upload error',
    UPLOADED = 'uploaded',
    CANCELLED = 'cancelled',
    PROCESSING = 'processing',
    REJECTED = 'rejected',
    COMPLETED = 'completed',
}

// Server response
export interface AssetBulkFile {
    id: string
    locationID: string
    status: AssetBulkFileStatus
    name: string
    checksum: string
    createdAt: string
    updatedAt: string
    createdBy?: GuidType
}

export interface CreateAssetBulkFileReq {
    locationID: GuidType
    status: AssetBulkFileStatus
    name: string
}

export interface PatchAssetBulkFileReq {
    locationID: GuidType
    status: AssetBulkFileStatus
}

export type OTSystemMap = Record<string, OTAssessments>

export interface OTAssessmentReq {
    q1: boolean
    q2: boolean
    q3: boolean
    q4: boolean
    q5: boolean
    q6: boolean
    q7: boolean
    q8: boolean
    q9: boolean
    q10: boolean
}

export interface OTAssessmentRes {
    id: GuidType
    locationID: GuidType
    systemID: GuidType
    questions: OTAssessmentReq
    vulnerabilityScore: number
    createdAt: string
    updatedAt: string
    inE26Scope: boolean
    criticalityLevel: string
}

export interface OTAssessments {
    assessments: OTAssessmentRes[]
    hardwareAssets: OTAsset[]
}

export type OTBulkAssetsUploadRes = Record<string, bulkAssetsUploadResult>

interface bulkAssetsUploadResult {
    errors?: {
        assetNo: number
        errors?: {
            error: string
            field: string
        }[]
    }[]
    success: object[]
}

// Column names in the OT Asset Excel template.
// Any reference to the column should use this enum.
export enum OTAssetKeys {
    NAME = 'Asset Name',
    TYPE = 'Type',
    VESSEL_SYSTEM = 'Vessel System',
    ZONE = 'Zone',
    BRAND = 'Brand/Manufacturer',
    MODEL = 'Model',
    DESCRIPTION = 'Short description of functionality/purpose',
    PHYSICAL_INTERFACES = 'Physical interfaces',
    COMMUNICATION_PROTOCOL = 'Supported communication protocols',
    SOFTWARE_TYPE = 'Name/type of system software',
    SOFTWARE_VERSION = 'Version and patch level of system software',
    STATUS = 'Lifecycle Status',
}

export enum OTAssetSpecialKeys {
    VESSEL_IMO = 'IMO Number',
    NO = 'No',
}

export enum OTAssetCustomKeys {
    IP_ADDRESS = 'IP Address',
}

export enum OTSupportedCommunicationProtocol {
    ETHERNET_OR_IP = 'Ethernet/IP',
}

// Keys that are meant to be used to
// reliably process the data. Not meant
// to be in the final output for the API
// or for users to see
export enum OTAssetInternalKeys {
    IDENTIFIER = '___identifier___',
}

export function isInternalOTAssetKey(value: string): value is OTAssetInternalKeys {
    for (const col of Object.values(OTAssetInternalKeys)) {
        if (col === value) {
            return true
        }
    }

    return false
}

export type OTAssetKeyType = `${OTAssetKeys}`

export type OTAssetRequiredKey =
    | OTAssetKeys.NAME
    | OTAssetKeys.TYPE
    | OTAssetKeys.VESSEL_SYSTEM
    | OTAssetKeys.ZONE
    | OTAssetKeys.STATUS

export type OTAssetEnumKey =
    | OTAssetKeys.SOFTWARE_TYPE
    | OTAssetKeys.ZONE
    | OTAssetKeys.VESSEL_SYSTEM
    | OTAssetKeys.PHYSICAL_INTERFACES
    | OTAssetKeys.COMMUNICATION_PROTOCOL
    | OTAssetKeys.TYPE
    | OTAssetKeys.STATUS

export function isOTAssetEnumKey(value: string): value is OTAssetEnumKey {
    const checks: string[] = [
        OTAssetKeys.SOFTWARE_TYPE,
        OTAssetKeys.ZONE,
        OTAssetKeys.VESSEL_SYSTEM,
        OTAssetKeys.PHYSICAL_INTERFACES,
        OTAssetKeys.COMMUNICATION_PROTOCOL,
        OTAssetKeys.TYPE,
        OTAssetKeys.STATUS,
    ]
    return checks.includes(value)
}

export function isOTAssetEnumKeySupportingMultipleValues(value: string): value is OTAssetEnumKey {
    const checks: string[] = [OTAssetKeys.PHYSICAL_INTERFACES]
    return checks.includes(value)
}

export function isOTAssetRequiredKey(value: string): value is OTAssetRequiredKey {
    const checks: string[] = [
        OTAssetKeys.NAME,
        OTAssetKeys.TYPE,
        OTAssetKeys.VESSEL_SYSTEM,
        OTAssetKeys.ZONE,
        OTAssetKeys.STATUS,
    ]
    return checks.includes(value)
}

export function isOTAssetKey(value: string): value is OTAssetKeyType {
    for (const col of Object.values(OTAssetKeys)) {
        if (col === value) {
            return true
        }
    }

    return false
}

export function isOTAssetCustomKey(value: string): value is OTAssetCustomKeys {
    return Object.values(OTAssetCustomKeys).some((key) => isEquivalentOTString(key, value))
}

export function normaliseString(value: string | undefined): string | undefined {
    return value?.trim().toLowerCase()
}

export function isEquivalentOTString(a: string | undefined, b: string | undefined): boolean {
    if (a === undefined || b === undefined) {
        return false
    }
    return normaliseString(a) === normaliseString(b)
}

export const OTAssetKeyToAssetOptionKeyMap: Record<OTAssetEnumKey, AssetOptionKeyEnum> = {
    [OTAssetKeys.SOFTWARE_TYPE]: AssetOptionKeyEnum.SOFTWARE,
    [OTAssetKeys.COMMUNICATION_PROTOCOL]: AssetOptionKeyEnum.COMMUNICATION_PROTOCOL,
    [OTAssetKeys.ZONE]: AssetOptionKeyEnum.ZONE,
    [OTAssetKeys.PHYSICAL_INTERFACES]: AssetOptionKeyEnum.PHYSICAL_INTERFACE,
    [OTAssetKeys.VESSEL_SYSTEM]: AssetOptionKeyEnum.SYSTEM,
    [OTAssetKeys.TYPE]: AssetOptionKeyEnum.ASSET_TYPE,
    [OTAssetKeys.STATUS]: AssetOptionKeyEnum.LIFECYCLE_STATUS,
}

// Individual row from OT asset excel template.
// If the type for 1 of the row is different later on,
// we will need to adjust this to either specify each column indivdually
// or use some ternary operator to define the type like `K extends OTAssetKeys.IMO_NUMBER ? number : string;`
// The & will allow custom fields
export type OTAssetRow = {
    [K in OTAssetKeys]?: string
} & {
    [key: string]: string
} & {
    [k in OTAssetSpecialKeys]?: string
}
