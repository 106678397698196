import {Action} from './actions'
import ActionType from './action-type'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import LoadingState from '../../../values/loading-state-enum'
import {isEqual} from 'lodash'
import {produce} from 'immer'
import {DEFAULT_VESSEL_MANAGEMENT_FILTER_STATE, VesselManagementFilterReduxState} from './state'

const vesselManagementFilterReducer = produce(
    (
        draft: VesselManagementFilterReduxState = DEFAULT_VESSEL_MANAGEMENT_FILTER_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_FILTER:
                draft.loadingState = LoadingState.RequestingData
                break
            case ActionType.RECEIVE_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.hasDeploymentIssues = action.payload.hasDeploymentIssues
                if (!isEqual(draft.locations, action.payload.locations)) {
                    draft.locations = action.payload.locations
                }
                draft.searchVesselTagTerm = action.payload.searchVesselTagTerm
                draft.searchVesselNameTerm = action.payload.searchVesselNameTerm
                draft.searchVesselTerm = action.payload.searchVesselTerm
                break

            case ActionType.TOGGLE_VESSEL_DEPLOYMENT_STATUS:
                draft.hasDeploymentIssues = action.payload
                break

            case ActionType.RESET_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.hasDeploymentIssues =
                    DEFAULT_VESSEL_MANAGEMENT_FILTER_STATE.hasDeploymentIssues
                draft.locations = DEFAULT_VESSEL_MANAGEMENT_FILTER_STATE.locations
                draft.searchVesselTagTerm =
                    DEFAULT_VESSEL_MANAGEMENT_FILTER_STATE.searchVesselTagTerm
                draft.searchVesselNameTerm =
                    DEFAULT_VESSEL_MANAGEMENT_FILTER_STATE.searchVesselNameTerm
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_VESSEL_MANAGEMENT_FILTER_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
export default vesselManagementFilterReducer
