import * as ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {Store} from 'redux'
import {ConnectedRouter} from 'connected-react-router'
import {enableMapSet} from 'immer'
import {makeAxios} from './api/axiosApi/axiosAPI'
import configureGlobalStore, {history} from './store/configure-store'
import AppState from './store/types/app-state'
import {unregister} from './RegisterServiceWorker'
import App from './root/App'
import {AxiosInstance} from 'axios'
import {FleetScoreOverTimeProvider} from './pages/dashboard-v2/components/fleet-score-over-time/contexts/fleet-score-over-time-provider'
import {TagsComponentProvider} from './pages/dashboard-v2/components/tags-component/contexts/tags-component-provider'
import {IncidentsWidgetProvider} from './pages/dashboard-v2/components/incident-response/contexts/incidents-widget-provider'
import './scss/main.scss'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'

enableMapSet()
export const store: Store<AppState> = configureGlobalStore()
export const REST: AxiosInstance = await makeAxios()

const consoleStyles =
    'font-family: "Open Sans", sans-serif; margin: 10px; padding: 10px; font-size: 16px; background-color: #303030; font-weight: bold; color: #e7e7e7'

// eslint-disable-next-line no-console
console.info('%cMedulla by CyberOwl', consoleStyles)

// eslint-disable-next-line no-console
console.info(
    `%cVersion: ${import.meta.env.VITE_MEDULLA_VERSION}${
        import.meta.env.VITE_BUILD_NUMBER ? `+${import.meta.env.VITE_BUILD_NUMBER}` : ''
    }`,
    consoleStyles,
)

// eslint-disable-next-line no-console
console.info(`%c${import.meta.env.VITE_UI_COMPONENTS_VERSION}`, consoleStyles)
const queryClient = new QueryClient()

// Workaround for now cause a lot of tests are importing
// this index.tsx file to get `store` and `REST` variable. Temporary solution until we refactor
// `store` and `REST` to their own file.
if (import.meta.env.NODE_ENV !== 'test') {
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <FleetScoreOverTimeProvider>
                    <TagsComponentProvider>
                        <IncidentsWidgetProvider>
                            <QueryClientProvider client={queryClient}>
                                <App />
                            </QueryClientProvider>
                        </IncidentsWidgetProvider>
                    </TagsComponentProvider>
                </FleetScoreOverTimeProvider>
            </ConnectedRouter>
        </Provider>,
        document.getElementById('root') as HTMLElement,
    )
}

// https://stackoverflow.com/a/49605422/28431371
unregister()
