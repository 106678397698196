import {Action} from './actions'
import ActionType from './action-type'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import LoadingState from '../../../values/loading-state-enum'
import {isEqual} from 'lodash'
import {produce} from 'immer'
import {
    DEFAULT_NETWORK_ASSETS_FILTER_STATE,
    NetworkAssetsFilterReduxState,
    NetworkAssetsSortType,
} from './state'
import {NetworkAssetState} from '../../../pages/network-assets/context/types/isolated-assets-type'
import {NetworkType} from '../../../pages/network-assets/context/types/network-type'
import {populateLocations} from '../../../utils/Utils'
import {GuidType} from '../../../values/generic-type-defintions'

const networkAssetsFilterReducer = produce(
    (
        draft: NetworkAssetsFilterReduxState = DEFAULT_NETWORK_ASSETS_FILTER_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_FILTER:
                draft.loadingState = LoadingState.RequestingData
                break

            case ActionType.SET_SORT_COLUMN:
                if (!isEqual(draft.orderBy, action.payload)) {
                    draft.orderBy = action.payload
                }
                break

            case ActionType.SET_SEARCHED_HOSTNAME:
                draft.searchedHostname = action.payload
                break

            case ActionType.SET_SEARCHED_PROPERTIES:
                draft.properties = action.payload
                break

            case ActionType.SET_SEARCHED_IP_ADDRESS:
                draft.searchedIpAddress = action.payload
                break

            case ActionType.SET_SEARCHED_MAC_DESCRIPTION:
                draft.searchedMacDescription = action.payload
                break

            case ActionType.SET_SEARCHED_LAST_ACTIVE:
                draft.fromRelativeLastSeen = action.payload
                break

            case ActionType.TOGGLE_NETWORK_ASSET_STATE:
                const {allNetworkAssetState, selectedNetworkAssetState, networkAssetStateNewValue} =
                    action.payload

                if (draft.states == undefined) {
                    draft.states = [...allNetworkAssetState]
                }
                if (networkAssetStateNewValue) {
                    draft.states = [...draft.states, selectedNetworkAssetState]
                } else {
                    draft.states.splice(draft.states.indexOf(selectedNetworkAssetState), 1)
                }
                if (draft.states.length === allNetworkAssetState.length) {
                    draft.states = undefined
                }

                break

            case ActionType.TOGGLE_ALL_NETWORK_ASSET_STATE:
                draft.states = action.payload ? undefined : new Array<NetworkAssetState>()
                break

            case ActionType.TOGGLE_NETWORK:
                const {allNetworks, selectedNetwork, networkNewValue} = action.payload
                if (draft.networks == undefined) {
                    draft.networks = [...allNetworks]
                }
                if (networkNewValue) {
                    draft.networks = [...draft.networks, selectedNetwork]
                } else {
                    draft.networks.splice(draft.networks.indexOf(selectedNetwork), 1)
                }
                if (draft.networks.length === allNetworks.length) {
                    draft.networks = undefined
                }
                break

            case ActionType.TOGGLE_ALL_NETWORKS:
                draft.networks = action.payload ? undefined : new Array<NetworkType>()
                break

            case ActionType.SELECT_BEHAVIOUR:
                const {allBehaviours, selectedBehaviour, behaviourNewValue} = action.payload

                if (behaviourNewValue) {
                    draft.behaviours == undefined
                        ? (draft.behaviours = [selectedBehaviour])
                        : (draft.behaviours = [...draft.behaviours, selectedBehaviour])
                } else {
                    draft.behaviours?.splice(draft.behaviours.indexOf(selectedBehaviour), 1)
                }
                if (
                    draft.behaviours?.length === allBehaviours.length ||
                    draft.behaviours?.length === 0
                ) {
                    draft.behaviours = undefined
                }

                break

            case ActionType.TOGGLE_ALL_BEHAVIOURS:
                draft.behaviours = action.payload ? undefined : new Array<GuidType>()
                break

            case ActionType.TOGGLE_FILTER_EXPANDED:
                if (!isEqual(draft.filterExpanded, action.payload)) {
                    draft.filterExpanded = action.payload
                }
                break

            case ActionType.RESET_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.orderBy = DEFAULT_NETWORK_ASSETS_FILTER_STATE.orderBy
                draft.searchedHostname = DEFAULT_NETWORK_ASSETS_FILTER_STATE.searchedHostname
                draft.properties = DEFAULT_NETWORK_ASSETS_FILTER_STATE.properties
                draft.searchedIpAddress = DEFAULT_NETWORK_ASSETS_FILTER_STATE.searchedIpAddress
                draft.searchedMacDescription =
                    DEFAULT_NETWORK_ASSETS_FILTER_STATE.searchedMacDescription
                draft.states = DEFAULT_NETWORK_ASSETS_FILTER_STATE.states
                draft.networks = DEFAULT_NETWORK_ASSETS_FILTER_STATE.networks
                draft.behaviours = DEFAULT_NETWORK_ASSETS_FILTER_STATE.behaviours
                draft.locations = DEFAULT_NETWORK_ASSETS_FILTER_STATE.locations
                draft.searchVesselTagTerm = DEFAULT_NETWORK_ASSETS_FILTER_STATE.searchVesselTagTerm
                draft.searchVesselNameTerm =
                    DEFAULT_NETWORK_ASSETS_FILTER_STATE.searchVesselNameTerm
                draft.searchVesselTerm = DEFAULT_NETWORK_ASSETS_FILTER_STATE.searchVesselTerm
                draft.properties = DEFAULT_NETWORK_ASSETS_FILTER_STATE.properties
                draft.fromRelativeLastSeen =
                    DEFAULT_NETWORK_ASSETS_FILTER_STATE.fromRelativeLastSeen
                draft.selectedFilterName = DEFAULT_NETWORK_ASSETS_FILTER_STATE.selectedFilterName
                break

            case ActionType.BULK_ASSIGN_SAVED_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.orderBy =
                    action.payload.criteria.orderBy.column === NetworkAssetsSortType.AIMEE_TIMESTAMP
                        ? {
                              column: NetworkAssetsSortType.TIMESTAMP,
                              isAscending: action.payload.criteria.orderBy.isAscending,
                          }
                        : action.payload.criteria.orderBy
                draft.states = action.payload.criteria.states
                draft.networks = action.payload.criteria.networks
                draft.behaviours = action.payload.criteria.behaviours
                draft.properties =
                    action.payload.criteria.vlan ?? action.payload.criteria.macAddress
                if (!draft.skipSettingFilter.fromRelativeLastSeen) {
                    draft.fromRelativeLastSeen = action.payload.criteria.fromRelativeLastSeen
                }
                draft.locations = populateLocations(action.payload.criteria.selectedVessels)
                draft.searchVesselTagTerm =
                    action.payload.criteria.searchVesselTagTerm ??
                    DEFAULT_NETWORK_ASSETS_FILTER_STATE.searchVesselTagTerm
                draft.selectedFilterName = action.payload.name
                draft.detailExpanded =
                    action.payload.detailExpanded ??
                    DEFAULT_NETWORK_ASSETS_FILTER_STATE.detailExpanded
                break

            case ActionType.TOGGLE_DETAIL_EXPANDED:
                if (!isEqual(draft.detailExpanded, action.payload)) {
                    draft.detailExpanded = action.payload
                }
                break
            case ActionType.SET_SELECTED_FILTER_NAME:
                draft.selectedFilterName = action.payload ?? ''
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_NETWORK_ASSETS_FILTER_STATE
                break
            case ActionType.SET_SKIP_SETTING_FILTER:
                draft.skipSettingFilter = action.payload
                break
            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
export default networkAssetsFilterReducer
