import {configureStore, EnhancedStore, Reducer, Middleware} from '@reduxjs/toolkit'
import {createBrowserHistory, History} from 'history'
import {routerMiddleware} from 'connected-react-router'

import {Api} from '../api/Api'
import {loadState, saveState} from './local-storage'
import AppState, {toPersistableAppState} from './types/app-state'
import PersistableAppState from './types/persistable-app-state'
import {INITIAL_STORE} from './types/initial-store'
import rootReducer from './root-reducer'

interface AsyncReducers {
    [key: string]: Reducer
}
type InjectReducerFunction = (key: string, reducer: Reducer) => void
export const history: History = createBrowserHistory()
export let api: Api

export default function configureGlobalStore(): EnhancedStore<AppState> {
    const persistedStore: PersistableAppState | undefined =
        import.meta.env.JEST_WORKER_ID == undefined ? loadState() : undefined
    api = new Api()

    const preloadedState = {...INITIAL_STORE, ...persistedStore}

    const asyncReducers: AsyncReducers = {}

    const createDynamicReducer = (): Reducer => {
        return rootReducer(history, asyncReducers)
    }

    const store = configureStore({
        reducer: createDynamicReducer(),
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: {
                    extraArgument: api,
                },

                serializableCheck: false,
            }).concat(routerMiddleware(history) as Middleware),
        devTools: process.env.NODE_ENV !== 'production',
    })

    const injectReducer: InjectReducerFunction = (key, reducer) => {
        if (!asyncReducers[key]) {
            asyncReducers[key] = reducer
            store.replaceReducer(createDynamicReducer())
        }
    }

    const enhancedStore = store as EnhancedStore<AppState> & {injectReducer: InjectReducerFunction}
    enhancedStore.injectReducer = injectReducer
    enhancedStore.subscribe(() => saveState(toPersistableAppState(enhancedStore.getState())))

    api.init(enhancedStore)

    if (persistedStore?.auth?.authToken === undefined) {
        api.initWs()
    }

    return enhancedStore
}

export const injectReducer = (
    key: string,
    asyncReducer: Reducer,
    store: EnhancedStore<AppState> & {injectReducer?: InjectReducerFunction},
): void => {
    if (store.injectReducer) {
        store.injectReducer(key, asyncReducer)
    }
}
