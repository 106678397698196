import {DEFAULT_MONA_ENGINE_MODES_STATE, MonaEngineModesReduxState} from './state'
import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import moment from 'moment'
import LoadingState from '../../../values/loading-state-enum'
import {produce} from 'immer'
import {MonaEngineMode} from '../../../values/mona_engine_mode'
import {getUpdatedDataArray} from '../reducer-helpers'

export const monaEngineModesReducer = produce(
    (
        draft: MonaEngineModesReduxState = DEFAULT_MONA_ENGINE_MODES_STATE,
        action: Action | LoggingOutAction,
    ): MonaEngineModesReduxState => {
        switch (action.type) {
            case ActionType.REQUEST_MONA_ENGINE_MODES:
                draft.loading = LoadingState.RequestingData
                if (draft.data?.length !== 0) {
                    draft.data = []
                }
                if (draft.lastUpdate != null) {
                    draft.lastUpdate = null
                }
                break

            case ActionType.RECEIVED_MONA_ENGINE_MODES:
                const flagChanged = draft.loading !== LoadingState.Loaded
                draft.loading = LoadingState.Loaded

                const {dataUpdated, data} = getUpdatedDataArray<MonaEngineMode>(
                    action.payload,
                    draft.data,
                    (a, b) => a.anomalyProcessingMode === b.anomalyProcessingMode,
                )
                if (dataUpdated) {
                    draft.data = data
                }

                if (flagChanged || dataUpdated || !draft.lastUpdate) {
                    draft.lastUpdate = moment()
                }

                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_MONA_ENGINE_MODES_STATE
                break
        }

        return draft
    },
)
