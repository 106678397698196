import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'
import {OTAssetLifecycleStatusEnum} from '../../../store/state/ot-assets/state'
import {Colors as MainColor} from '../../../theme/colours'
import {StyledTippy} from './vessel-card.styled'

interface Props {
    status: OTAssetLifecycleStatusEnum
}

const Colors: Record<OTAssetLifecycleStatusEnum, string> = {
    [OTAssetLifecycleStatusEnum.ACTIVE]: MainColor.green3,
    [OTAssetLifecycleStatusEnum.DECOMMISSIONED]: '#D9D9D9',
    [OTAssetLifecycleStatusEnum.INACTIVE]: MainColor.darkRed,
    [OTAssetLifecycleStatusEnum.HOT_SPARE]: '#F8B24B',
    [OTAssetLifecycleStatusEnum.COLD_SPARE]: '#0D47A1',
}

const CircleIcon = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: ${spacing(2)};
    background: ${(props) => props.color};
    flex-shrink: 0;
`
const Label = styled.span`
    font-size: 14px;
    line-height: 19.07px;
    text-transform: capitalize;
    overflow-x: hidden;
    text-overflow: ellipsis;
`
const Container = styled.div`
    display: flex;
    align-items: center;
`

export function AssetLifeCycle({status}: Props): JSX.Element {
    return (
        <Container>
            <CircleIcon color={Colors[status] || Colors.inactive} />
            <StyledTippy content={AssetStatusToString(status)}>
                <Label>{AssetStatusToString(status)}</Label>
            </StyledTippy>
        </Container>
    )
}

export function AssetStatusToString(status: OTAssetLifecycleStatusEnum): string {
    // Replace underscores to spaces and make the first letter uppercase
    return status.replace(/_/g, ' ').replace(/^\w/, (c: string) => c.toUpperCase())
}

export const AssetStatusDropdownOptions = Object.values(OTAssetLifecycleStatusEnum).map(
    (status) => ({
        value: status,
        label: AssetStatusToString(status),
    }),
)

export const DefaultAssetStatusDropdownOption = AssetStatusDropdownOptions.find(
    (option) => option.value === OTAssetLifecycleStatusEnum.ACTIVE,
)!
