import LoadingState from '../../../values/loading-state-enum'
import {GuidType} from '../../../values/generic-type-defintions'
import {OTAssetRow} from '../../../pages/ot-inventory/contexts/types/ot-types'

export enum OTAssetLifecycleStatusEnum {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    DECOMMISSIONED = 'decommissioned',
    COLD_SPARE = 'cold_spare',
    HOT_SPARE = 'hot_spare',
}

export const AssetStatusToLabelMap: Record<OTAssetStatusType, string> = {
    active: 'Active',
    inactive: 'Inactive',
    decommissioned: 'Decommissioned',
    cold_spare: 'Cold Spare',
    hot_spare: 'Hot Spare',
}

type OTAssetStatusType = `${OTAssetLifecycleStatusEnum}`

interface OTAssetCustomField {
    name: string
    value: string
}

/**
 * Enum keys for GET/POST for asset list.
 */
export enum OTAssetKeyEnum {
    SYSTEM = 'systemID',
    NAME = 'name',
}

export interface OTAsset {
    id: string
    assetTypeID: string
    name: string
    vendor: string
    status: OTAssetLifecycleStatusEnum
    zoneID: string
    systemID: string
    locationID: string
    revision: number
    createdAt: string
    updatedAt: string
    model: string
    description: string
    softwareVersion: string
    communicationProtocolID: string
    softwareID: string
    systemName: string
    assetTypeName: string
    zoneName: string
    communicationProtocolName: string
    softwareName: string
    physicalInterfaces: PhysicalInterface[]
    customFields: OTAssetCustomField[]
    [key: string]: string | number | PhysicalInterface[] | OTAssetCustomField[]
}

interface PhysicalInterface {
    id: string
    name: string
    created_at: string
    updated_at: string
}

export interface CreateOTAssetReq {
    [key: string]: GuidType | string | undefined | GuidType[] | number | OTAssetCustomField[]
    assetTypeID: GuidType
    name: string
    vendor: string
    model: string
    zoneID: GuidType
    systemID: GuidType
    locationID?: GuidType
    description: string
    physicalInterfaceIDs: GuidType[]
    softwareID: GuidType
    softwareVersion: string
    communicationProtocolID: string
    customFields: OTAssetCustomField[]
}

export interface OTAssetStateReduxState {
    loadingState: LoadingState
    locationID: string
    assets: OTAsset[]
    openedModal: boolean
    selectedModalAsset?: OTAsset
    deleteAssetIsLoading: boolean
    deleteAssetError: string
    cortexSuggestion: Partial<OTAssetRow[]>
}

export const DEFAULT_OTASSETS_STATE: OTAssetStateReduxState = {
    loadingState: LoadingState.NotPopulated,
    locationID: '',
    assets: [],
    openedModal: false,
    deleteAssetIsLoading: false,
    deleteAssetError: '',
    cortexSuggestion: [],
}
