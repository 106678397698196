enum ActionType {
    REQUEST_ASSETS = '[OT ASSETS] REQUEST',
    SET_ASSETS = '[OT ASSETS] SET',
    FAIL_FETCH_ASSETS = '[OT ASSETS] FETCH ERROR',
    ADD_ASSET = '[OT ASSETS] ADD',
    UPDATE_ASSET = '[OT ASSET] UPDATE',
    REMOVED_ASSET = '[OT ASSET] REMOVED',
    FAIL_REMOVE_ASSET = '[OT ASSET] REMOVE ERROR',
    OPEN_MODAL = '[OT ASSET] OPEN MODAL',
    CLOSE_MODAL = '[OT ASSET] CLOSE MODAL',
    REMOVE_ASSET_REQUEST = '[OT ASSET] REMOVE ASSET REQUEST',
    REMOVE_ASSET_SUCCESS = '[OT ASSET] REMOVE ASSET SUCCESS',
    REMOVE_ASSET_FAILURE = '[OT ASSET] REMOVE ASSET FAILURE',
    SET_CORTEX_SUGGESTIONS = '[OT ASSET] SET CORTEX SUGGESTIONS',
    RESET_CORTEX_SUGGESTIONS = '[OT ASSET] RESET CORTEX SUGGESTIONS',
}

export default ActionType
