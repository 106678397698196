import {Action} from './actions'
import ActionType from './action-type'
import LoadingState from '../../../values/loading-state-enum'
import {produce} from 'immer'
import {DEFAULT_OTASSETS_STATE, OTAssetStateReduxState} from './state'
import {OTAssetInternalKeys} from '../../../pages/ot-inventory/contexts/types/ot-types'

const OTAssetsReducer = produce(
    (draft: OTAssetStateReduxState = DEFAULT_OTASSETS_STATE, action: Action) => {
        switch (action.type) {
            case ActionType.REQUEST_ASSETS:
                draft.loadingState = LoadingState.RequestingData
                draft.locationID = action.payload.locationID
                draft.assets = []

                return draft
            case ActionType.SET_ASSETS:
                if (draft.locationID !== action.payload.locationID) {
                    return draft
                }

                draft.loadingState = LoadingState.Loaded
                draft.locationID = action.payload.locationID
                draft.assets = action.payload.data

                return draft
            case ActionType.FAIL_FETCH_ASSETS:
                if (draft.locationID !== action.payload.locationID) {
                    return draft
                }

                draft.loadingState = LoadingState.Errored

                return draft

            case ActionType.ADD_ASSET:
                draft.assets.push(action.payload.data)
                return draft

            case ActionType.UPDATE_ASSET:
                const assetIndex = draft.assets.findIndex(
                    (asset) => asset.id === action.payload.assetId,
                )

                draft.assets[assetIndex] = action.payload.data
                return draft

            case ActionType.REMOVE_ASSET_REQUEST:
                draft.deleteAssetIsLoading = true
                draft.deleteAssetError = ''
                return draft

            case ActionType.REMOVE_ASSET_SUCCESS:
                draft.assets = draft.assets.filter((asset) => asset.id !== action.payload.id)
                draft.deleteAssetIsLoading = false
                return draft

            case ActionType.REMOVE_ASSET_FAILURE:
                draft.deleteAssetError = action.payload.error
                draft.deleteAssetIsLoading = false
                return draft

            case ActionType.OPEN_MODAL:
                draft.openedModal = true
                draft.selectedModalAsset = action.payload.asset
                return draft
            // Close the delete asset modal
            case ActionType.CLOSE_MODAL:
                draft.openedModal = false
                // reset error state
                draft.deleteAssetError = ''
                // reset the selected asset
                draft.selectedModalAsset = undefined
                return draft

            case ActionType.SET_CORTEX_SUGGESTIONS:
                if (!draft.cortexSuggestion) {
                    draft.cortexSuggestion = action.payload
                } else {
                    action.payload.forEach((suggestion) => {
                        if (!suggestion) {
                            return
                        }
                        const existing = draft.cortexSuggestion.find(
                            (entry) =>
                                entry &&
                                entry[OTAssetInternalKeys.IDENTIFIER] ===
                                    suggestion[OTAssetInternalKeys.IDENTIFIER],
                        )
                        if (existing) {
                            Object.assign(existing, suggestion)
                        } else {
                            draft.cortexSuggestion.push(suggestion)
                        }
                    })
                }
                return draft

            case ActionType.RESET_CORTEX_SUGGESTIONS:
                draft.cortexSuggestion = []
                return draft

            default:
                break
        }
        return draft
    },
)

export default OTAssetsReducer
