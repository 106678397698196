import {Action} from './actions'
import ActionType from './action-type'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import LoadingState from '../../../values/loading-state-enum'
import {isEqual} from 'lodash'
import {produce} from 'immer'
import {DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE, SoftwareInventoryFilterReduxState} from './state'
import {populateLocations} from '../../../utils/Utils'

const softwareInventoryFilterReducer = produce(
    (
        draft: SoftwareInventoryFilterReduxState = DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_FILTER:
                draft.loadingState = LoadingState.RequestingData
                break
            case ActionType.RECEIVE_FILTER:
                draft.loadingState = LoadingState.Loaded
                if (!isEqual(draft.sortColumn, action.payload)) {
                    draft.sortColumn = action.payload.sortColumn
                }
                draft.searchedFirstDetected = action.payload.searchedFirstDetected
                draft.searchedLastActive = action.payload.searchedLastActive
                draft.searchedCompanyName = action.payload.searchedCompanyName
                draft.searchedProductName = action.payload.searchedProductName
                if (!isEqual(draft.filteredSoftwareStatus, action.payload.filteredSoftwareStatus)) {
                    draft.filteredSoftwareStatus = action.payload.filteredSoftwareStatus
                }
                if (!isEqual(draft.locations, action.payload.locations)) {
                    draft.locations = action.payload.locations
                }
                if (!isEqual(draft.detailExpanded, action.payload)) {
                    draft.detailExpanded =
                        action.payload.detailExpanded ??
                        DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.detailExpanded
                }
                draft.searchVesselTagTerm = action.payload.searchVesselTagTerm
                draft.searchVesselNameTerm = action.payload.searchVesselNameTerm
                break

            case ActionType.SET_SORT_COLUMN:
                if (!isEqual(draft.sortColumn, action.payload)) {
                    draft.sortColumn = action.payload
                }
                break

            case ActionType.SET_SEARCHED_FIRST_SEEN:
                draft.searchedFirstDetected = action.payload
                break
            case ActionType.SET_SEARCHED_LAST_ACTIVE:
                draft.searchedLastActive = action.payload
                break
            case ActionType.SET_SEARCHED_COMPANY_NAME:
                draft.searchedCompanyName = action.payload
                break
            case ActionType.SET_SEARCHED_PRODUCT_NAME:
                draft.searchedProductName = action.payload
                break

            case ActionType.RESET_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.sortColumn = DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.sortColumn
                draft.searchedFirstDetected =
                    DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.searchedFirstDetected
                draft.searchedLastActive =
                    DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.searchedLastActive
                draft.searchedCompanyName =
                    DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.searchedCompanyName
                draft.searchedProductName =
                    DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.searchedProductName
                draft.locations = DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.locations
                draft.searchVesselTagTerm =
                    DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.searchVesselTagTerm
                draft.searchVesselNameTerm =
                    DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.searchVesselNameTerm
                draft.selectedFilterName =
                    DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.selectedFilterName
                draft.filteredSoftwareStatus =
                    DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.filteredSoftwareStatus
                break
            case ActionType.BULK_ASSIGN_SAVED_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.sortColumn = action.payload.criteria.sortColumn
                draft.searchedFirstDetected =
                    action.payload.criteria.searchedFirstDetected ??
                    DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.searchedFirstDetected
                draft.searchedLastActive =
                    action.payload.criteria.searchedLastActive ??
                    DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.searchedLastActive
                draft.searchedCompanyName = action.payload.criteria.searchedCompanyName
                draft.searchedProductName = action.payload.criteria.searchedProductName
                draft.locations = populateLocations(action.payload.criteria.searchedVessels)
                draft.searchVesselTagTerm =
                    action.payload.criteria.searchVesselTagTerm ??
                    DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.searchVesselTagTerm
                draft.selectedFilterName = action.payload.name
                draft.filteredSoftwareStatus = action.payload.criteria.filteredSoftwareStatus
                draft.detailExpanded =
                    action.payload.detailExpanded ??
                    DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.detailExpanded
                break

            case ActionType.SET_SELECTED_FILTER_NAME:
                draft.selectedFilterName = action.payload ?? ''
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE
                break
            case ActionType.TOGGLE_ALL_SOFTWARE_STATUSES:
                draft.filteredSoftwareStatus = action.payload ? undefined : []
                break
            case ActionType.TOGGLE_FILTER_EXPANDED:
                if (!isEqual(draft.filterExpanded, action.payload)) {
                    draft.filterExpanded = action.payload
                }
                break
            case ActionType.TOGGLE_DETAIL_EXPANDED:
                if (!isEqual(draft.detailExpanded, action.payload)) {
                    draft.detailExpanded = action.payload
                }
                break
            case ActionType.TOGGLE_SOFTWARE_STATUS:
                const {allSoftwareStatuses, selectedSoftwareStatus, softwareStatusNewValue} =
                    action.payload
                if (draft.filteredSoftwareStatus == undefined) {
                    draft.filteredSoftwareStatus = [...allSoftwareStatuses]
                }
                if (softwareStatusNewValue) {
                    draft.filteredSoftwareStatus = [
                        ...draft.filteredSoftwareStatus,
                        selectedSoftwareStatus,
                    ]
                } else {
                    draft.filteredSoftwareStatus.splice(
                        draft.filteredSoftwareStatus.indexOf(selectedSoftwareStatus),
                        1,
                    )
                }
                if (draft.filteredSoftwareStatus.length === allSoftwareStatuses.length) {
                    draft.filteredSoftwareStatus = undefined
                }
                break
            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
export default softwareInventoryFilterReducer
