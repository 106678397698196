import ActionType from './action-type'
import {Action} from './actions'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {DEFAULT_ETS_VALUE_STATE, EtsValueReduxState} from './state'
import {produce} from 'immer'
import {isEqual} from 'lodash'

export const etsValueReducer = produce(
    (draft: EtsValueReduxState = DEFAULT_ETS_VALUE_STATE, action: Action | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_ETS:
                draft.isFetching = true
                break
            case ActionType.RECEIVE_ETS:
                draft.isFetching = false
                if (!isEqual(draft.ets, action.payload)) {
                    draft.ets = action.payload
                }
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_ETS_VALUE_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
