import {Action} from './actions'
import ActionType from './action-type'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import LoadingState from '../../../values/loading-state-enum'
import {DEFAULT_INCIDENTS_FILTER_STATE, IncidentsFilterReduxState} from './state'
import {isEqual} from 'lodash'
import {produce} from 'immer'
import {populateLocations} from '../../../utils/Utils'
import {DEFAULT_INCIDENTS_DETAIL_EXPANDED} from '../../../pages/incidents-v3/contexts/types/incidents-details-type'

const incidentsFilterReducer = produce(
    (
        draft: IncidentsFilterReduxState = DEFAULT_INCIDENTS_FILTER_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_FILTER:
                draft.loadingState = LoadingState.RequestingData
                break
            case ActionType.RECEIVE_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.selectedFilterName = action.payload.selectedFilterName
                if (!isEqual(draft.locations, action.payload.locations)) {
                    draft.locations = populateLocations(action.payload.locations)
                }
                draft.searchVesselTagTerm = action.payload.searchVesselTagTerm
                draft.searchVesselNameTerm = action.payload.searchVesselNameTerm
                draft.sortByNotes =
                    action.payload.sortByNotes ?? DEFAULT_INCIDENTS_FILTER_STATE.sortByNotes
                if (!isEqual(draft.detailExpanded, action.payload)) {
                    draft.detailExpanded =
                        action.payload.detailExpanded ?? DEFAULT_INCIDENTS_DETAIL_EXPANDED
                }
                break
            case ActionType.COLUMN_SORT:
                draft.orderIncidentsBy.column = action.payload.column
                draft.orderIncidentsBy.direction = action.payload.direction
                break
            case ActionType.SET_SEARCH_INCIDENT_NO:
                draft.searchIncidentNo = action.payload
                break
            case ActionType.FILTER_BY_STATUS:
                if (!isEqual(draft.filterByStatus, action.payload)) {
                    draft.filterByStatus = action.payload
                }
                break
            case ActionType.FILTER_BY_SEVERITY:
                draft.filterBySeverities = action.payload
                break
            case ActionType.SORT_BY_NOTES:
                draft.sortByNotes = action.payload
                break
            case ActionType.SET_SELECTED_INCIDENT_TYPE:
                draft.selectedIncidentType = action.payload
                break
            case ActionType.SET_SELECTED_CREATED_FROM_RELATIVE:
                draft.createdFromRelative = action.payload
                break
            case ActionType.SET_SELECTED_CREATED_TO_RELATIVE:
                draft.createdToRelative = action.payload
                break
            case ActionType.SET_SELECTED_UPDATED_FROM_RELATIVE:
                draft.updatedFromRelative = action.payload
                break
            case ActionType.SET_SELECTED_UPDATED_TO_RELATIVE:
                draft.updatedToRelative = action.payload
                break
            case ActionType.SET_SELECTED_CREATED_TIME_RANGE:
                draft.selectedCreatedFromTimeRange = action.payload[0]
                draft.selectedCreatedToTimeRange = action.payload[1]
                break
            case ActionType.SET_SELECTED_UPDATED_TIME_RANGE:
                draft.selectedUpdatedFromTimeRange = action.payload[0]
                draft.selectedUpdatedToTimeRange = action.payload[1]
                break
            case ActionType.SET_SELECTED_VESSEL_ASSIGNED_EMAIL:
                draft.assignedToVesselEmail = action.payload
                break
            case ActionType.SET_SELECTED_ASSIGNED_TO_USER:
                draft.assignedTo = action.payload
                draft.guestAssignedToEmail = undefined
                break
            case ActionType.SET_SELECTED_ASSIGNED_TO_GUEST:
                draft.guestAssignedToEmail = action.payload
                draft.assignedTo = undefined
                break
            case ActionType.SET_WATCH_INCIDENTS:
                draft.watchedByCurrentUser = action.payload
                break
            case ActionType.SET_INCIDENTS_WITH_NEW_NOTES:
                draft.unseenIncidentResponses = action.payload
                break
            case ActionType.RESET_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.orderIncidentsBy = DEFAULT_INCIDENTS_FILTER_STATE.orderIncidentsBy
                draft.filterBySeverities = DEFAULT_INCIDENTS_FILTER_STATE.filterBySeverities
                draft.filterByStatus = DEFAULT_INCIDENTS_FILTER_STATE.filterByStatus
                draft.searchIncidentNo = DEFAULT_INCIDENTS_FILTER_STATE.searchIncidentNo
                draft.selectedIncidentType = DEFAULT_INCIDENTS_FILTER_STATE.selectedIncidentType
                draft.selectedCreatedFromTimeRange =
                    DEFAULT_INCIDENTS_FILTER_STATE.selectedCreatedFromTimeRange
                draft.selectedCreatedToTimeRange =
                    DEFAULT_INCIDENTS_FILTER_STATE.selectedCreatedToTimeRange
                draft.selectedUpdatedFromTimeRange =
                    DEFAULT_INCIDENTS_FILTER_STATE.selectedUpdatedFromTimeRange
                draft.selectedUpdatedToTimeRange =
                    DEFAULT_INCIDENTS_FILTER_STATE.selectedUpdatedToTimeRange
                draft.locations = DEFAULT_INCIDENTS_FILTER_STATE.locations
                draft.searchVesselTagTerm = DEFAULT_INCIDENTS_FILTER_STATE.searchVesselTagTerm
                draft.searchVesselNameTerm = DEFAULT_INCIDENTS_FILTER_STATE.searchVesselNameTerm
                draft.assignedTo = DEFAULT_INCIDENTS_FILTER_STATE.assignedTo
                draft.guestAssignedToEmail = DEFAULT_INCIDENTS_FILTER_STATE.guestAssignedToEmail
                draft.watchedByCurrentUser = DEFAULT_INCIDENTS_FILTER_STATE.watchedByCurrentUser
                draft.unseenIncidentResponses =
                    DEFAULT_INCIDENTS_FILTER_STATE.unseenIncidentResponses
                draft.selectedFilterName = DEFAULT_INCIDENTS_FILTER_STATE.selectedFilterName
                draft.createdFromRelative = DEFAULT_INCIDENTS_FILTER_STATE.createdFromRelative
                draft.createdToRelative = DEFAULT_INCIDENTS_FILTER_STATE.createdToRelative
                draft.updatedFromRelative = DEFAULT_INCIDENTS_FILTER_STATE.updatedFromRelative
                draft.updatedToRelative = DEFAULT_INCIDENTS_FILTER_STATE.updatedToRelative
                draft.assignedToVesselEmail = DEFAULT_INCIDENTS_FILTER_STATE.assignedToVesselEmail
                break
            case ActionType.BULK_ASSIGN_SAVED_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.orderIncidentsBy = action.payload.criteria.orderIncidentsBy
                draft.sortByNotes =
                    action.payload.sortByNotes ?? DEFAULT_INCIDENTS_FILTER_STATE.sortByNotes
                draft.filterBySeverities = action.payload.criteria.filterBySeverities
                draft.filterByStatus = action.payload.criteria.filterByStatus
                draft.selectedIncidentType = action.payload.criteria.selectedIncidentType
                draft.selectedCreatedFromTimeRange =
                    action.payload.criteria.selectedCreatedFromTimeRange
                draft.selectedCreatedToTimeRange =
                    action.payload.criteria.selectedCreatedToTimeRange
                draft.selectedUpdatedFromTimeRange =
                    action.payload.criteria.selectedUpdatedFromTimeRange
                draft.selectedUpdatedToTimeRange =
                    action.payload.criteria.selectedUpdatedToTimeRange
                draft.createdFromRelative = action.payload.criteria.createdFromRelative
                draft.createdToRelative = action.payload.criteria.createdToRelative
                draft.updatedFromRelative = action.payload.criteria.updatedFromRelative
                draft.updatedToRelative = action.payload.criteria.updatedToRelative
                draft.locations = populateLocations(action.payload.criteria.locations)
                draft.searchVesselTagTerm = action.payload.criteria.searchVesselTagTerm
                draft.assignedTo = action.payload.criteria.assignedTo
                draft.guestAssignedToEmail = action.payload.criteria.guestAssignedToEmail
                draft.watchedByCurrentUser = action.payload.criteria.watchedByCurrentUser
                draft.unseenIncidentResponses = action.payload.criteria.unseenIncidentResponses
                draft.assignedToVesselEmail = action.payload.criteria.assignedToVesselEmail
                draft.selectedFilterName =
                    action.payload.name ?? DEFAULT_INCIDENTS_FILTER_STATE.selectedFilterName
                draft.detailExpanded =
                    action.payload.detailExpanded ?? DEFAULT_INCIDENTS_DETAIL_EXPANDED
                break
            case ActionType.SET_SELECTED_FILTER_NAME:
                draft.selectedFilterName = action.payload ?? ''
                break
            case ActionType.TOGGLE_DETAIL_EXPANDED:
                if (!isEqual(draft.detailExpanded, action.payload)) {
                    draft.detailExpanded = action.payload
                }
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_INCIDENTS_FILTER_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
export default incidentsFilterReducer
