import {LocationIdType} from '../../store/state/locations/state'

export interface ReportsFilterCriteria {
    selectedReportType: string | undefined
    selectedFromTimeRange: string | undefined
    selectedToTimeRange: string | undefined
    includeWholeFleetReports: boolean
    textSearch: string | undefined
}

export const DEFAULT_NETWORK_ASSETS_FILTER_CRITERIA: ReportsFilterCriteria = {
    selectedReportType: undefined,
    selectedFromTimeRange: undefined,
    selectedToTimeRange: undefined,
    includeWholeFleetReports: true,
    textSearch: undefined,
}

export interface ReportsFilter {
    selectedReportType: string | undefined
    selectedFromTimeRange: string | undefined
    selectedToTimeRange: string | undefined
    includeWholeFleetReports: boolean
    locations: LocationIdType[] | undefined
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    textSearch: string | undefined
}

export const DEFAULT_REPORTS_FILTER: ReportsFilter = {
    selectedReportType: undefined,
    selectedFromTimeRange: undefined,
    selectedToTimeRange: undefined,
    includeWholeFleetReports: true,
    locations: undefined,
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
    textSearch: undefined,
}

export interface ReportsFilterForUserPref {
    selectedFilterName: string
    locations: LocationIdType[] | undefined
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
}
