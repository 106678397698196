import {
    DEFAULT_SELF_MONITORING_ENGINE_EVENTS_STATE,
    SelfMonitoringEngineEventsReduxState,
} from './state'
import ActionType from './action-type'
import {Action} from './actions'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import moment from 'moment'
import LoadingState from '../../../values/loading-state-enum'
import {produce} from 'immer'
import {getUpdatedDataArray} from '../reducer-helpers'
import {SelfMonitoringEngineEvent} from '../../../values/self-monitoring-event'

export const selfMonitoringEngineEventsReducer = produce(
    (
        draft: SelfMonitoringEngineEventsReduxState = DEFAULT_SELF_MONITORING_ENGINE_EVENTS_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_SELF_MONITORING_ENGINE_EVENTS:
                draft.loadingEvents = LoadingState.RequestingData
                break
            case ActionType.RECEIVED_SELF_MONITORING_ENGINE_EVENTS:
                draft.loadingEvents = LoadingState.Loaded
                draft.lastUpdate = moment()
                const {dataUpdated, data} = getUpdatedDataArray<SelfMonitoringEngineEvent>(
                    action.payload,
                    draft.data,
                    (a, b) => a.identity === b.identity,
                )
                if (dataUpdated) {
                    draft.data = data
                }
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_SELF_MONITORING_ENGINE_EVENTS_STATE
                break

            /* istanbul ignore next */
            default:
                return draft
        }
        return draft
    },
)
