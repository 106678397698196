import {produce} from 'immer'
import {LoggingOutAction} from '../../../../../../store/state/session-data/actions'
import {AllActions} from './actions'
import SessionActionType from '../../../../../../store/state/session-data/action-type'
import {ActionType} from './action-type'
import {FleetScoreOverTimeState} from '../types/fleet-score-over-time-state'
import {DEFAULT_FLEET_SCORE_OVER_TIME_STATE} from '../types/default-fleet-score-over-time-state'
import LoadingState from '../../../../../../values/loading-state-enum'
import {isEqual} from 'lodash'

export const fleetScoreOverTimeReducer = produce(
    (draft: FleetScoreOverTimeState, action: AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_FILTER:
                draft.loadingFilterState = LoadingState.RequestingData
                break
            case ActionType.RECEIVE_PREFS_FILTER:
                draft.loadingFilterState = LoadingState.Loaded
                if (
                    !isEqual(
                        draft.fleetScoreOverTimeFilter.searchedVesselTagTerm,
                        action.payload.searchedVesselTagTerm,
                    )
                ) {
                    draft.fleetScoreOverTimeFilter.searchedVesselTagTerm =
                        action.payload.searchedVesselTagTerm
                }
                break
            case ActionType.SHOW_FILTER:
                draft.showFilter = action.payload
                break
            case ActionType.SET_SEARCH_VESSEL_TAG_TERM:
                draft.fleetScoreOverTimeFilter.searchedVesselTagTerm = action.payload
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_FLEET_SCORE_OVER_TIME_STATE
                break
        }
        return draft
    },
)
