// import { RouterState } from 'connected-react-router'
// import { LocationState, Location } from 'history'
import AppState from './app-state'
import {DEFAULT_AUTH_STATE} from '../state/auth/state'
import {DEFAULT_CONFIG_STATE} from '../state/config/state'
import {DEFAULT_INDICATORS_STATE} from '../state/indicators/state'
import {DEFAULT_LOCATIONS_STATE} from '../state/locations/state'
import {DEFAULT_NODES_STATE} from '../state/nodes/state'
import {DEFAULT_ROLES_STATE} from '../state/roles/state'
import {DEFAULT_THREAT_MEASURES_STATE} from '../state/threat-measures/state'
import {DEFAULT_USERS_STATE} from '../state/users/state'
import {DEFAULT_ETS_VALUE_STATE} from '../state/ets-value/state'
import {DEFAULT_ETS_TREND_STATE} from '../state/ets-trend/state'
import {DEFAULT_SFM_STATE} from '../state/sfm-scores/state'
import {DEFAULT_WATCH_STATE} from '../state/watched-nodes/state'
import {DEFAULT_LATEST_LOCATION_THREAT_SCORES_STATE} from '../state/latest-location-threat-scores/state'
import {DEFAULT_THREAT_SCORES_STATE} from '../state/latest-event-timestamps/state'
import {DEFAULT_NETWORK_ANOMALY_MATRIX_STATE} from '../state/network-anomaly-matrix/state'
import {DEFAULT_ENGINE_STATUS_STATE} from '../state/engine-status/state'
import {DEFAULT_SELF_MONITORING_ENGINE_EVENTS_STATE} from '../state/self-monitoring-events/state'
import {DEFAULT_SELF_MONITORING_ENGINE_RULES_STATE} from '../state/self-monitoring-rules/state'
import {DEFAULT_SESSION_DATA_STATE} from '../state/session-data/state'
import {DEFAULT_CURRENT_USER_STATE} from '../state/current-user/state'
import {DEFAULT_MY_VESSELS_FILTERS_STATE} from '../state/my-vessels-filters/types/default-my-vessel-filter-state'
import {DEFAULT_INCIDENTS_FILTER_STATE} from '../state/incidents-filter/state'
import {DEFAULT_LATEST_UNKNOWN_ASSET_DATA_STATE} from '../state/latest-unknown-asset/state'
import {DEFAULT_MONA_ENGINE_MODES_STATE} from '../state/mona-engine-modes/state'
import {DEFAULT_INCIDENT_TYPES_STATE} from '../state/incident-types/state'
import {DEFAULT_UNSAVED_INCIDENT_NOTE_ALERT_MODAL_STATE} from '../state/unsaved-incident-note-alert-modal/state'
import {DEFAULT_WATCH_INCIDENTS_STATE} from '../state/watched-incidents/state'
import {DEFAULT_EMAIL_RECIPIENTS_STATE} from '../state/email-recipient-management/state'
import {DEFAULT_VESSEL_TAGS_STATE} from '../state/vessel-tags/state'
import {DEFAULT_LOCATION_TAGS_SUMMARY_STATE} from '../state/location-tags-summary/state'
import {DEFAULT_VESSEL_FILTER_STATE} from '../state/vessel-filter/types/default-vessel-filter-state'
import {DEFAULT_REPORTS_FILTER_STATE} from '../state/reports-filter/state'
import {DEFAULT_NETWORK_ASSETS_FILTER_STATE} from '../state/network-assets-filter/state'
import {DEFAULT_AUDIT_LOG_STATE} from '../state/audit-log/state'
import {DEFAULT_LOCATION_TAG_THREAT_SCORE_STATE} from '../state/location-tag-score/state'
import {DEFAULT_USB_INVENTORY_FILTER_STATE} from '../state/usb-inventory-filter/state'
import {DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE} from '../state/software-inventory-filter/state'
import {DEFAULT_SAVED_FILTERS_STATE} from '../state/saved-filters/state'
import {DEFAULT_EXTENDED_DATA_REQUEST_STATE} from '../state/extended-data-request/state'
import {DEFAULT_METRICS_BETA_FILTER_STATE} from '../state/metrics-filter-beta/state'
import {DEFAULT_METRIC_TYPES_STATE} from '../state/metric-types/metricTypesSlice'
import {DEFAULT_VESSELS_BETA_FILTER} from '../state/vessels-beta-filter/state'
import {DEFAULT_VESSEL_MANAGEMENT_FILTER_STATE} from '../state/vessel-management-filter/state'
import {DEFAULT_OTASSETS_STATE} from '../state/ot-assets/state'
import {DEFAULT_INCIDENTS_REPORT_FILTER_STATE} from '../state/incidents-report-filter/state'
import {DEFAULT_FEEDBACK_MODAL_STATE} from '../state/feedback/state'

export const INITIAL_STORE: AppState = {
    auth: DEFAULT_AUTH_STATE,
    config: DEFAULT_CONFIG_STATE,
    indicators: DEFAULT_INDICATORS_STATE,
    roles: DEFAULT_ROLES_STATE,
    nodes: DEFAULT_NODES_STATE,
    threatMeasures: DEFAULT_THREAT_MEASURES_STATE,
    locations: DEFAULT_LOCATIONS_STATE,
    users: DEFAULT_USERS_STATE,
    etsValue: DEFAULT_ETS_VALUE_STATE,
    etsTrend: DEFAULT_ETS_TREND_STATE,
    sfmScores: DEFAULT_SFM_STATE,
    watchedNodes: DEFAULT_WATCH_STATE,
    latestLocationThreatScores: DEFAULT_LATEST_LOCATION_THREAT_SCORES_STATE,
    latestEventTimestamps: DEFAULT_THREAT_SCORES_STATE,
    networkAnomalyMatrix: DEFAULT_NETWORK_ANOMALY_MATRIX_STATE,
    engineStatus: DEFAULT_ENGINE_STATUS_STATE,
    selfMonitoringEngineEvents: DEFAULT_SELF_MONITORING_ENGINE_EVENTS_STATE,
    selfMonitoringEngineRules: DEFAULT_SELF_MONITORING_ENGINE_RULES_STATE,
    sessionData: DEFAULT_SESSION_DATA_STATE,
    currentUser: DEFAULT_CURRENT_USER_STATE,
    myVesselsFilter: DEFAULT_MY_VESSELS_FILTERS_STATE,
    incidentsFilter: DEFAULT_INCIDENTS_FILTER_STATE,
    latestUnknownAsset: DEFAULT_LATEST_UNKNOWN_ASSET_DATA_STATE,
    monaEngineModes: DEFAULT_MONA_ENGINE_MODES_STATE,
    incidentTypes: DEFAULT_INCIDENT_TYPES_STATE,
    unsavedIncidentNoteAlertModal: DEFAULT_UNSAVED_INCIDENT_NOTE_ALERT_MODAL_STATE,
    watchIncidents: DEFAULT_WATCH_INCIDENTS_STATE,
    emailRecipientData: DEFAULT_EMAIL_RECIPIENTS_STATE,
    vesselTags: DEFAULT_VESSEL_TAGS_STATE,
    locationTagsSummary: DEFAULT_LOCATION_TAGS_SUMMARY_STATE,
    vesselFilter: DEFAULT_VESSEL_FILTER_STATE,
    reportsFilter: DEFAULT_REPORTS_FILTER_STATE,
    networkAssetsFilter: DEFAULT_NETWORK_ASSETS_FILTER_STATE,
    usbInventoryFilter: DEFAULT_USB_INVENTORY_FILTER_STATE,
    softwareInventoryFilter: DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE,
    auditLog: DEFAULT_AUDIT_LOG_STATE,
    locationTagThreatScore: DEFAULT_LOCATION_TAG_THREAT_SCORE_STATE,
    savedFilters: DEFAULT_SAVED_FILTERS_STATE,
    extendedDataRequest: DEFAULT_EXTENDED_DATA_REQUEST_STATE,
    metricsBetaFilter: DEFAULT_METRICS_BETA_FILTER_STATE,
    metricTypes: DEFAULT_METRIC_TYPES_STATE,
    vesselsBetaFilter: DEFAULT_VESSELS_BETA_FILTER,
    vesselManagementFilter: DEFAULT_VESSEL_MANAGEMENT_FILTER_STATE,
    OTAssets: DEFAULT_OTASSETS_STATE,
    incidentsReportFilter: DEFAULT_INCIDENTS_REPORT_FILTER_STATE,
    feedbackModal: DEFAULT_FEEDBACK_MODAL_STATE,
}
