import ActionType from './action-type'
import {Action} from './actions'
import {
    DEFAULT_LOCATION_TAGS_SUMMARY_STATE,
    LocationTagsSummaryMapType,
    LocationTagsSummaryReduxState,
} from './state'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {produce} from 'immer'

export const locationTagsSummaryReducer = produce(
    (
        draft: LocationTagsSummaryReduxState = DEFAULT_LOCATION_TAGS_SUMMARY_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_LOCATION_TAGS_SUMMARY:
                draft.isFetchingTagSummary = true
                break

            case ActionType.RECEIVE_LOCATION_TAGS_SUMMARY:
                draft.isFetchingTagSummary = false

                draft.averageLocationScoresByLocationTags = action.payload
                    .averageLocationScoresByLocationTags as LocationTagsSummaryMapType

                draft.incidentResponsesCountsByLocationTags = action.payload
                    .incidentResponsesCountsByLocationTags as LocationTagsSummaryMapType

                draft.maxLocationScoresByLocationTags = action.payload
                    .maxLocationScoresByLocationTags as LocationTagsSummaryMapType

                draft.unknownAssetCountsByLocationTags = action.payload
                    .unknownAssetCountsByLocationTags as LocationTagsSummaryMapType

                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_LOCATION_TAGS_SUMMARY_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
