import {Action} from './actions'
import ActionType from './action-type'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import LoadingState from '../../../values/loading-state-enum'
import {isEqual} from 'lodash'
import {produce} from 'immer'
import {DEFAULT_INCIDENTS_REPORT_FILTER_STATE, IncidentsReportFilterReduxState} from './state'
import {LocationIdType} from '../locations/state'

export function populateLocations(
    locations: string | LocationIdType[] | undefined,
): Set<LocationIdType> | undefined {
    if (!locations || locations === 'all-selected') {
        return undefined
    } else if (Array.isArray(locations) && locations.length === 0) {
        return undefined
    } else if (locations === 'all-deselected') {
        return new Set<LocationIdType>()
    } else if (Array.isArray(locations) && locations.length !== 0) {
        return new Set<LocationIdType>(locations)
    }
    return undefined
}

const incidentsReportFilterReducer = produce(
    (
        draft: IncidentsReportFilterReduxState = DEFAULT_INCIDENTS_REPORT_FILTER_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_FILTER:
                draft.loadingState = LoadingState.RequestingData
                break

            case ActionType.TOGGLE_FILTER_EXPANDED:
                if (!isEqual(draft.filterExpanded, action.payload)) {
                    draft.filterExpanded = action.payload
                }
                break
            case ActionType.SET_DATE_FILTER:
                draft.fromDate = action.payload.fromDate
                draft.toDate = action.payload.toDate
                break
            case ActionType.RESET_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.fromDate = DEFAULT_INCIDENTS_REPORT_FILTER_STATE.fromDate
                draft.toDate = DEFAULT_INCIDENTS_REPORT_FILTER_STATE.toDate
                draft.locations = DEFAULT_INCIDENTS_REPORT_FILTER_STATE.locations
                draft.searchVesselTagTerm =
                    DEFAULT_INCIDENTS_REPORT_FILTER_STATE.searchVesselTagTerm
                draft.searchVesselNameTerm =
                    DEFAULT_INCIDENTS_REPORT_FILTER_STATE.searchVesselNameTerm
                draft.selectedFilterName = DEFAULT_INCIDENTS_REPORT_FILTER_STATE.selectedFilterName
                break

            case ActionType.BULK_ASSIGN_SAVED_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.fromDate = action.payload.criteria.fromDate
                draft.toDate = action.payload.criteria.toDate
                draft.locations = populateLocations(action.payload.criteria.locations)
                draft.searchVesselTagTerm = action.payload.criteria.searchVesselTagTerm
                draft.selectedFilterName = action.payload.name
                break

            case ActionType.SET_SELECTED_FILTER_NAME:
                draft.selectedFilterName = action.payload ?? ''
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_INCIDENTS_REPORT_FILTER_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
export default incidentsReportFilterReducer
