import {DEFAULT_ENGINE_STATUS_STATE, EngineStatusReduxState} from './state'
import ActionType from './action-type'
import {Action} from './actions'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {EngineStatus} from '../../../values/engine_status/engine-status'
import moment from 'moment'
import {getUpdatedDataArray} from '../reducer-helpers'
import LoadingState from '../../../values/loading-state-enum'
import {produce} from 'immer'

function checkAllEnginesAreRunning(engineStatuses: EngineStatus[]): boolean {
    if (engineStatuses?.length === 0) {
        return false
    }
    return engineStatuses.some((engineStatus) =>
        engineStatus.consumerStates.some((consumerState) => consumerState.status !== 'RUNNING'),
    )
        ? false
        : true
}

export const engineStatusReducer = produce(
    (
        draft: EngineStatusReduxState = DEFAULT_ENGINE_STATUS_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_ENGINE_STATUS:
                draft.loading = LoadingState.RequestingData
                break

            case ActionType.RECEIVED_ENGINE_STATUS:
                draft.loading = LoadingState.Loaded
                draft.lastUpdate = moment()
                const {dataUpdated, data} = getUpdatedDataArray<EngineStatus>(
                    action.payload,
                    draft.data,
                    (a, b) => a.engineName === b.engineName,
                )
                if (dataUpdated) {
                    draft.data = data
                }
                draft.allEnginesRunning = checkAllEnginesAreRunning(action.payload)
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_ENGINE_STATUS_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
