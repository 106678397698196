enum ActionType {
    REQUEST_FILTER = '[REPORTS_FILTER] REQUEST FILTER',
    RECEIVE_FILTER = '[REPORTS_FILTER] RECEIVE FILTER',
    SET_SELECTED_RATING = '[REPORTS_FILTER] SET SELECTED RATING',
    SET_SELECTED_TIME_RANGE_FROM = '[REPORTS_FILTER] SET SELECTED TIME RANGE FROM',
    SET_SELECTED_TIME_RANGE_TO = '[REPORTS_FILTER] SET SELECTED TIME RANGE TO',
    TOGGLE_VESSEL = '[REPORTS_FILTER] TOGGLE VESSEL',
    TOGGLE_ALL_VESSELS = '[REPORTS_FILTER] TOGGLE ALL VESSELS',
    SET_SELECTED_REPORT_TYPE = '[REPORTS_FILTER] SET SELECTED REPORT TYPE',
    TOGGLE_WHOLE_FLEET_REPORTS = '[REPORTS_FILTER] TOGGLE WHOLE FLEET REPORTS',
    RESET_FILTER = '[REPORTS_FILTER] RESET FILTER',
    BULK_ASSIGN_SAVED_FILTER = '[REPORTS FILTER] BULK ASSIGN SAVED FILTER',
    SET_SELECTED_FILTER_NAME = '[REPORTS FILTER] SET SELECTED FILTER NAME',
    SET_TITLE_AND_NOTES_TEXT_FILTER = '[REPORTS FILTER] SET TITLE AND NOTES TEXT FILTER',
}

export default ActionType
