import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit'
import {REST} from '../../../index'
import {MetricsSchema} from '../../../pages/policy-management/contexts/metrics-policy/type/metrics-policy-schema'
import {MetricType} from '../../../pages/metrics-beta/contexts/types/metrics-response'
import {StringUtils} from '../../../utils/Utils'
import LoadingState from '../../../values/loading-state-enum'
import {isEqual} from 'lodash'
import {
    FormattedMetricData,
    MetricTypeResponseMap,
    MetricTypesReduxState,
} from './metric-types.types'

const TYPES_ENDPOINT: string = 'metrics/api/v1/metrics/types'
const METRICS_SCHEMA_ENDPOINT: string = 'metrics/api/v1/metrics/schema'
import SessionActionType from '../session-data/action-type'

export const DEFAULT_METRIC_TYPES_STATE: MetricTypesReduxState = {
    loading: LoadingState.NotPopulated,
    dataMetricResponseMap: undefined,
    formattedMetricData: [] as FormattedMetricData[],
}

export const fetchMetricTypes = createAsyncThunk(
    'metricTypes/fetchMetricTypes',
    async (_: void, {rejectWithValue}) => {
        try {
            const [schemasResponse, metricTypesResponse] = await Promise.all([
                REST.get<MetricsSchema[]>(METRICS_SCHEMA_ENDPOINT),
                REST.get<Record<string, string[]>>(TYPES_ENDPOINT),
            ])

            const receivedSchemas: MetricsSchema[] = schemasResponse.data
            const receivedMetricTypes = new Map(
                Object.entries(metricTypesResponse.data),
            ) as MetricTypeResponseMap

            const formattedMetricData: FormattedMetricData[] = []

            const schemaLookup: Map<MetricType, MetricsSchema[]> = new Map()
            receivedSchemas.forEach((schema: MetricsSchema) => {
                const metricGroup = schema.metricGroup as unknown as MetricType
                if (!schemaLookup.has(metricGroup)) {
                    schemaLookup.set(metricGroup, [])
                }
                schemaLookup.get(metricGroup)!.push(schema)
            })

            for (const [metricTypeTop, metricTypeArray] of receivedMetricTypes) {
                const schemaArray = schemaLookup.get(metricTypeTop) || []

                metricTypeArray.forEach((metricType: MetricType) => {
                    const matchedSchema = schemaArray.find(
                        (metricFromSchema) => metricType === metricFromSchema.name,
                    )

                    if (matchedSchema) {
                        formattedMetricData.push({
                            name: matchedSchema.name,
                            displayName: matchedSchema.displayName,
                            metricGroup: matchedSchema.metricGroup,
                        })
                    } else {
                        formattedMetricData.push({
                            name: metricType,
                            displayName: StringUtils.capitaliseString(
                                metricType.replace(/([A-Z])/g, ' $1'),
                            ),
                            metricGroup: '',
                        })
                    }
                })
            }

            return {receivedMetricTypes, formattedMetricData}
        } catch (error) {
            console.error(error)
            return rejectWithValue('Failed to fetch metric types')
        }
    },
)

const metricTypesSlice = createSlice({
    name: 'metricTypes',
    initialState: DEFAULT_METRIC_TYPES_STATE,
    reducers: {
        resetMetricTypesState(): MetricTypesReduxState {
            return {...DEFAULT_METRIC_TYPES_STATE}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMetricTypes.pending, (state: MetricTypesReduxState) => {
                state.loading = LoadingState.RequestingData
            })
            .addCase(
                fetchMetricTypes.fulfilled,
                (
                    state: MetricTypesReduxState,
                    action: PayloadAction<{
                        receivedMetricTypes: MetricTypeResponseMap
                        formattedMetricData: FormattedMetricData[]
                    }>,
                ) => {
                    state.loading = LoadingState.Loaded

                    if (!isEqual(state.dataMetricResponseMap, action.payload.receivedMetricTypes)) {
                        state.dataMetricResponseMap = action.payload.receivedMetricTypes
                    }

                    if (!isEqual(state.formattedMetricData, action.payload.formattedMetricData)) {
                        state.formattedMetricData = action.payload.formattedMetricData
                    }
                },
            )
            .addCase(fetchMetricTypes.rejected, (state: MetricTypesReduxState, action) => {
                state.loading = LoadingState.Errored
                console.error('Error fetching metric types:', action.payload)
            })
            .addCase(SessionActionType.LOGGING_OUT, (): MetricTypesReduxState => {
                return {...DEFAULT_METRIC_TYPES_STATE}
            })
    },
})

export const {resetMetricTypesState} = metricTypesSlice.actions
export const metricTypesReducer = metricTypesSlice.reducer
