import {Action} from './actions'
import ActionType from './action-type'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {VesselFiltersState, ZOOM_LEVEL} from './types/my-vessel-filter-state'
import {DEFAULT_MY_VESSELS_FILTERS_STATE} from './types/default-my-vessel-filter-state'
import {produce} from 'immer'
import {LocationIdType} from '../locations/state'
import LoadingState from '../../../values/loading-state-enum'
import {isEqual} from 'lodash'

export const myVesselsFiltersReducer = produce(
    (
        draft: VesselFiltersState = DEFAULT_MY_VESSELS_FILTERS_STATE,
        action: Action | LoggingOutAction,
    ): VesselFiltersState => {
        switch (action.type) {
            case ActionType.REQUEST_FILTER:
                draft.loadingState = LoadingState.RequestingData
                break

            case ActionType.RECEIVE_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.searchAssetTerm = action.payload.searchAssetTerm
                if (!isEqual(draft.searchVesselTagTerm, action.payload.searchVesselTagTerm)) {
                    draft.searchVesselTagTerm = action.payload.searchVesselTagTerm
                }
                if (!isEqual(draft.searchVesselNameTerm, action.payload.searchVesselNameTerm)) {
                    draft.searchVesselNameTerm = action.payload.searchVesselNameTerm
                }
                if (action.payload.searchVesselNameTerm.length !== 0) {
                    draft.searchVesselTerm = action.payload.searchVesselNameTerm
                    draft.showXButton = true
                }
                draft.zoomLevel = action.payload.zoomLevel
                if (!isEqual(draft.HIGH, action.payload.HIGH)) {
                    draft.HIGH = action.payload.HIGH
                }
                if (!isEqual(draft.MEDIUM, action.payload.MEDIUM)) {
                    draft.MEDIUM = action.payload.MEDIUM
                }
                if (!isEqual(draft.LOW, action.payload.LOW)) {
                    draft.LOW = action.payload.LOW
                }
                if (!isEqual(draft.locations, action.payload.locations)) {
                    draft.locations = action.payload.locations
                }
                if (!isEqual(draft.assetSort, action.payload.assetSort)) {
                    draft.assetSort = action.payload.assetSort
                }
                if (!isEqual(draft.vesselSort, action.payload.vesselSort)) {
                    draft.vesselSort = action.payload.vesselSort
                }
                break

            case ActionType.TOGGLE_ALL_ASSETS:
                draft.HIGH.high = action.payload
                draft.HIGH.medium = action.payload
                draft.HIGH.low = action.payload
                draft.MEDIUM.high = action.payload
                draft.MEDIUM.medium = action.payload
                draft.MEDIUM.low = action.payload
                draft.LOW.high = action.payload
                draft.LOW.medium = action.payload
                draft.LOW.low = action.payload
                break

            case ActionType.TOGGLE_VALUE_THRESHOLD:
                const {bool, value, threshold} = action.payload
                draft[value][threshold] = bool
                break

            case ActionType.TOGGLE_ALL_VESSELS:
                draft.locations = action.payload ? undefined : new Set<LocationIdType>()
                break

            case ActionType.TOGGLE_VESSEL: {
                const {allLocations, location, newValue} = action.payload
                if (draft.locations == undefined) {
                    draft.locations = new Set<LocationIdType>(allLocations)
                }

                if (newValue) {
                    draft.locations.add(location)
                } else {
                    draft.locations.delete(location)
                }

                if (draft.locations.size === allLocations?.length) {
                    draft.locations = undefined
                }

                break
            }

            case ActionType.TOGGLE_FILTERED_VESSELS: {
                const {allLocations, filteredLocations, newValue} = action.payload
                if (draft.locations == undefined) {
                    draft.locations = new Set<LocationIdType>(allLocations)
                }
                if (newValue) {
                    filteredLocations.map((location) => draft.locations?.add(location))
                } else {
                    filteredLocations.map((location) => draft.locations?.delete(location))
                }
                if (draft.locations.size === allLocations?.length) {
                    draft.locations = undefined
                }
                break
            }

            case ActionType.DEEP_LINK_VESSEL: {
                draft.locations = new Set<LocationIdType>()
                draft.locations.add(action.payload)
                draft.zoomLevel = ZOOM_LEVEL.FOUR
                break
            }

            case ActionType.SET_SEARCH_ASSET_TERM:
                draft.searchAssetTerm = action.payload
                break

            case ActionType.SET_SEARCH_VESSEL_TAG_TERM:
                draft.searchVesselTagTerm = action.payload
                break

            case ActionType.SET_SEARCH_VESSEL_NAME_TERM:
                draft.searchVesselNameTerm = action.payload
                break

            case ActionType.SET_SEARCH_VESSEL_TERM:
                draft.searchVesselTerm = action.payload
                break

            case ActionType.SET_SHOW_X_BUTTON:
                draft.showXButton = action.payload
                break

            case ActionType.ASSET_SORT:
                draft.assetSort.field = action.payload.field
                draft.assetSort.direction = action.payload.direction
                break

            case ActionType.VESSEL_SORT:
                draft.vesselSort.field = action.payload.field
                draft.vesselSort.direction = action.payload.direction
                break

            case ActionType.CHANGE_ZOOM_LEVEL:
                draft.zoomLevel = action.payload
                break

            case ActionType.RESET_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.assetSort = DEFAULT_MY_VESSELS_FILTERS_STATE.assetSort
                draft.vesselSort = DEFAULT_MY_VESSELS_FILTERS_STATE.vesselSort
                draft.zoomLevel = DEFAULT_MY_VESSELS_FILTERS_STATE.zoomLevel
                draft.HIGH = DEFAULT_MY_VESSELS_FILTERS_STATE.HIGH
                draft.LOW = DEFAULT_MY_VESSELS_FILTERS_STATE.LOW
                draft.MEDIUM = DEFAULT_MY_VESSELS_FILTERS_STATE.MEDIUM
                draft.searchAssetTerm = DEFAULT_MY_VESSELS_FILTERS_STATE.searchAssetTerm
                draft.searchVesselTagTerm = DEFAULT_MY_VESSELS_FILTERS_STATE.searchVesselTagTerm
                draft.searchVesselNameTerm = DEFAULT_MY_VESSELS_FILTERS_STATE.searchVesselNameTerm
                draft.locations = DEFAULT_MY_VESSELS_FILTERS_STATE.locations
                draft.searchVesselTerm = DEFAULT_MY_VESSELS_FILTERS_STATE.searchVesselTerm
                draft.showXButton = DEFAULT_MY_VESSELS_FILTERS_STATE.showXButton
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_MY_VESSELS_FILTERS_STATE
                break
        }
        return draft
    },
)
