import {Action} from './actions'
import ActionType from './action-type'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {produce} from 'immer'
import {LocationIdType} from '../locations/state'
import LoadingState from '../../../values/loading-state-enum'
import {isEqual} from 'lodash'
import {VesselFilterReduxState} from './types/vessel-filter-state'
import {DEFAULT_VESSEL_FILTER_STATE} from './types/default-vessel-filter-state'

export const vesselFilterReducer = produce(
    (
        draft: VesselFilterReduxState = DEFAULT_VESSEL_FILTER_STATE,
        action: Action | LoggingOutAction,
    ): VesselFilterReduxState => {
        switch (action.type) {
            case ActionType.SET_PAGE_TYPE:
                draft.pageType = action.payload
                draft.loadingState = LoadingState.NotPopulated
                draft.locations = DEFAULT_VESSEL_FILTER_STATE.locations
                draft.searchVesselNameTerm = DEFAULT_VESSEL_FILTER_STATE.searchVesselNameTerm
                break

            case ActionType.SET_VESSEL_FILTER_PREF:
                draft.loadingState = LoadingState.Loaded
                draft.pageType = action.payload.pageType

                if (
                    !isEqual(
                        draft.searchVesselTagTerm,
                        action.payload.vesselFilter.searchVesselTagTerm,
                    )
                ) {
                    draft.searchVesselTagTerm = action.payload.vesselFilter.searchVesselTagTerm
                }
                if (
                    !isEqual(
                        draft.searchVesselNameTerm,
                        action.payload.vesselFilter.searchVesselNameTerm,
                    )
                ) {
                    draft.searchVesselNameTerm = action.payload.vesselFilter.searchVesselNameTerm
                }

                if (action.payload.vesselFilter.searchVesselNameTerm?.length !== 0) {
                    draft.searchVesselTerm = action.payload.vesselFilter.searchVesselNameTerm
                    draft.showXButton = true
                } else {
                    draft.searchVesselTerm = DEFAULT_VESSEL_FILTER_STATE.searchVesselTerm
                    draft.showXButton = false
                }
                if (!isEqual(draft.locations, action.payload.vesselFilter.locations)) {
                    draft.locations = action.payload.vesselFilter.locations
                }
                if (!isEqual(draft.vesselSort, action.payload.vesselFilter.vesselSort)) {
                    draft.vesselSort =
                        action.payload.vesselFilter.vesselSort ??
                        DEFAULT_VESSEL_FILTER_STATE.vesselSort
                }
                break

            case ActionType.TOGGLE_ALL_VESSELS:
                draft.locations = action.payload ? undefined : new Set<LocationIdType>()
                break

            case ActionType.TOGGLE_VESSEL: {
                const {allLocations, location, newValue} = action.payload
                if (draft.locations == undefined) {
                    draft.locations = new Set<LocationIdType>(allLocations)
                }

                if (newValue) {
                    draft.locations.add(location)
                } else {
                    draft.locations.delete(location)
                }

                if (draft.locations.size === allLocations?.length) {
                    draft.locations = undefined
                }

                break
            }

            case ActionType.TOGGLE_FILTERED_VESSELS: {
                const {allLocations, filteredLocations, newValue} = action.payload
                if (draft.locations == undefined) {
                    draft.locations = new Set<LocationIdType>(allLocations)
                }
                if (newValue) {
                    filteredLocations.map((location) => draft.locations?.add(location))
                } else {
                    filteredLocations.map((location) => draft.locations?.delete(location))
                }
                if (draft.locations.size === allLocations?.length) {
                    draft.locations = undefined
                }
                break
            }
            case ActionType.SET_VESSEL_FOR_INTERLINKING: {
                const vesselForInterlinking = action.payload
                draft.locations = new Set<LocationIdType>(vesselForInterlinking)
                break
            }
            case ActionType.SET_SEARCH_VESSEL_TAG_TERM:
                draft.searchVesselTagTerm = action.payload
                break

            case ActionType.SET_SEARCH_VESSEL_NAME_TERM:
                draft.searchVesselNameTerm = action.payload
                break

            case ActionType.SET_SEARCH_VESSEL_TERM:
                draft.searchVesselTerm = action.payload
                break

            case ActionType.SET_SHOW_X_BUTTON:
                draft.showXButton = action.payload
                break

            case ActionType.VESSEL_SORT:
                draft.vesselSort.field = action.payload.field
                draft.vesselSort.direction = action.payload.direction
                break

            case ActionType.RESET_VESSEL_FILTER:
                draft.locations = DEFAULT_VESSEL_FILTER_STATE.locations
                draft.searchVesselTagTerm = DEFAULT_VESSEL_FILTER_STATE.searchVesselTagTerm
                draft.searchVesselNameTerm = DEFAULT_VESSEL_FILTER_STATE.searchVesselNameTerm
                draft.searchVesselTerm = DEFAULT_VESSEL_FILTER_STATE.searchVesselTerm
                draft.showXButton = DEFAULT_VESSEL_FILTER_STATE.showXButton
                draft.vesselSort = DEFAULT_VESSEL_FILTER_STATE.vesselSort
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_VESSEL_FILTER_STATE
                break
        }
        return draft
    },
)
