import ActionType from './action-type'
import * as Actions from './actions'
import {LoggedOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {DEFAULT_AUTH_STATE, AuthReduxState} from './state'
import {produce} from 'immer'

const authReducer = produce(
    (draft: AuthReduxState = DEFAULT_AUTH_STATE, action: Actions.Action | LoggedOutAction) => {
        switch (action.type) {
            case SessionActionType.LOGGED_OUT:
                draft = DEFAULT_AUTH_STATE
                break

            case ActionType.RECEIVE_AUTH_TOKEN:
                draft.authToken = action.payload
                break
            case ActionType.CLEAR_AUTH_TOKEN:
                draft.authToken = undefined
                break
            /* istanbul ignore next */
            default:
                return draft
        }
        return draft
    },
)
export default authReducer
