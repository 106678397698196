export class TimeRange {
    public readonly value: number
    public readonly unit: TimeUnit
    public readonly name: string

    private readonly toStringValue: string

    public static valueOf(text: string): TimeRange {
        return TIME_RANGES[text.trim()]
    }

    public static fromName(name: string): TimeRange | null {
        for (const key in TIME_RANGES) {
            const value = TIME_RANGES[key]
            if (value.name === name) {
                return value
            }
        }
        return null
    }

    public constructor(value: number, unit: TimeUnit, name: string) {
        this.value = value
        this.unit = unit
        this.name = name
        this.toStringValue = `${this.value}${this.unit}`
    }

    public toHourString(prefix?: string): string {
        let hour = this.value
        if (this.unit === 'd') {
            hour = this.value * 24
        }
        return prefix ? `${prefix}${hour}H` : `${hour}H`
    }

    public toString = (): string => {
        return this.toStringValue
    }

    public toRelativeString(): string {
        return `now() - ${this.toStringValue}`
    }

    public static parseDateFromReadableString(dataRange: string): TimeRange {
        switch (dataRange) {
            case 'Last 24 hours':
                return LAST_24H
            case 'Last 7 days':
                return LAST_7D
            case 'Last 30 days':
                return LAST_30D
            default:
                return LAST_24H
        }
    }
}

export type TimeUnit = 'h' | 'd'

export const LAST_1H: TimeRange = new TimeRange(1, 'h', '1 Hour')
export const LAST_12H: TimeRange = new TimeRange(12, 'h', '12 Hours')
export const LAST_24H: TimeRange = new TimeRange(24, 'h', '24 Hours')
export const LAST_7D: TimeRange = new TimeRange(7, 'd', '7 Days')
export const LAST_30D: TimeRange = new TimeRange(30, 'd', '30 Days')
export const LAST_90D: TimeRange = new TimeRange(90, 'd', '90 Days')

export interface TimeRangeDictionary {
    [key: string]: TimeRange
}

const TIME_RANGES: TimeRangeDictionary = {}
TIME_RANGES[LAST_1H.toString()] = LAST_1H
TIME_RANGES[LAST_12H.toString()] = LAST_12H
TIME_RANGES[LAST_24H.toString()] = LAST_24H
TIME_RANGES[LAST_7D.toString()] = LAST_7D
TIME_RANGES[LAST_30D.toString()] = LAST_30D
TIME_RANGES[LAST_90D.toString()] = LAST_90D
