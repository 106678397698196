import {DEFAULT_INCIDENT_TYPES_STATE, IncidentTypesReduxState} from './state'
import ActionType from './action-type'
import {Action} from './actions'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {getUpdatedDataArray} from '../reducer-helpers'
import LoadingState from '../../../values/loading-state-enum'
import {produce} from 'immer'
import {IncidentType} from '../../../pages/incidents-v3/contexts/types/incident-type'

export const incidentTypesReducer = produce(
    (
        draft: IncidentTypesReduxState = DEFAULT_INCIDENT_TYPES_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_INCIDENT_TYPES:
                draft.loading = LoadingState.RequestingData
                break

            case ActionType.RECEIVED_INCIDENT_TYPES:
                draft.loading = LoadingState.Loaded
                const {dataUpdated, data} = getUpdatedDataArray<IncidentType>(
                    action.payload,
                    draft.data,
                    (a, b) => a.displayName === b.displayName,
                )
                if (dataUpdated) {
                    draft.data = data
                }
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_INCIDENT_TYPES_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
