import {DEFAULT_LATEST_UNKNOWN_ASSET_DATA_STATE, LatestUnknownDataReduxState} from './state'

import {Action} from './actions'
import ActionType from './action-type'
import {produce} from 'immer'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import {isEqual} from 'lodash'

const latestUnknownAssetReducer = produce(
    (
        draft: LatestUnknownDataReduxState = DEFAULT_LATEST_UNKNOWN_ASSET_DATA_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_LATEST_UNKNOWN_ASSET_DATA:
                draft.loading = true
                break
            case ActionType.RECEIVE_LATEST_UNKNOWN_ASSET_DATA:
                draft.loading = false
                if (!isEqual(draft.unknownAssetData, action.payload)) {
                    draft.unknownAssetData = action.payload
                }
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_LATEST_UNKNOWN_ASSET_DATA_STATE
                break
            /* istanbul ignore next */
            default:
                return draft
        }
        return draft
    },
)
export default latestUnknownAssetReducer
