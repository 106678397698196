import ActionType from './action-type'
import {Action} from './actions'
import {produce} from 'immer'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import {DEFAULT_ROLES_STATE, RolesReduxState} from './state'
import {getUpdatedDataArray} from '../reducer-helpers'
import {Role} from '../../../values/Role'

const rolesReducer = produce(
    (draft: RolesReduxState = DEFAULT_ROLES_STATE, action: Action | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_ROLES_FOR_CURRENT_USER:
                draft.isFetching = true
                break
            case ActionType.RECEIVE_ROLES_FOR_CURRENT_USER:
                draft.isFetching = false
                const {dataUpdated, data} = getUpdatedDataArray<Role>(
                    action.payload,
                    draft.roles,
                    (a, b) => a === b,
                )
                if (dataUpdated) {
                    draft.roles = data
                }
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_ROLES_STATE
                break

            /* istanbul ignore next */
            default:
                return draft
        }
        return draft
    },
)
export default rolesReducer
