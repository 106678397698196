import ActionType from './action-type'
import {Action} from './actions'
import {DEFAULT_LOCATION_TAG_THREAT_SCORE_STATE, LocationTagThreatScoreReduxState} from './state'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {produce} from 'immer'
import {LAST_12H, LAST_24H, LAST_30D, LAST_7D, LAST_90D, TimeRange} from '../../../values/TimeRange'
import {getUpdatedDataArray} from '../reducer-helpers'
import {LocationTagScore} from '../../../values/location-tag-score'

function requestLocationTagTrends(
    currentState: LocationTagThreatScoreReduxState,
    timeRange: TimeRange,
): LocationTagThreatScoreReduxState {
    switch (timeRange) {
        case LAST_12H:
            if (currentState.isFetchingLast12H) {
                return currentState
            }

            return {
                ...currentState,
                isFetchingLast12H: true,
            }

        case LAST_24H:
            if (currentState.isFetchingLast24H) {
                return currentState
            }

            return {
                ...currentState,
                isFetchingLast24H: true,
            }

        case LAST_7D:
            if (currentState.isFetchingLast7D) {
                return currentState
            }

            return {
                ...currentState,
                isFetchingLast7D: true,
            }

        case LAST_30D:
            if (currentState.isFetchingLast30D) {
                return currentState
            }

            return {
                ...currentState,
                isFetchingLast30D: true,
            }
        case LAST_90D:
            if (currentState.isFetchingLast90D) {
                return currentState
            }

            return {
                ...currentState,
                isFetchingLast90D: true,
            }
        default:
            return currentState
    }
}

function receiveLocationTagTrends(
    currentTrends: LocationTagScore[],
    receivedData: LocationTagScore[],
): LocationTagScore[] {
    const {dataUpdated, data} = getUpdatedDataArray<LocationTagScore>(
        receivedData,
        currentTrends,
        (a, b) => a.time === b.time,
    )

    if (!dataUpdated && data.length === currentTrends?.length) {
        return currentTrends
    }

    return data
}

function receive12HourLocationTagTrendData(
    currentState: LocationTagThreatScoreReduxState,
    receivedTrends: LocationTagScore[],
): LocationTagThreatScoreReduxState {
    const updatedTrends = receiveLocationTagTrends(currentState.trendLast12H, receivedTrends)
    if (updatedTrends === currentState.trendLast12H && !currentState.isFetchingLast12H) {
        return currentState
    }

    return {
        ...currentState,
        isFetchingLast12H: false,
        trendLast12H: updatedTrends,
    }
}

export const locationTagThreatScoreReducer = produce(
    (
        draft: LocationTagThreatScoreReduxState = DEFAULT_LOCATION_TAG_THREAT_SCORE_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_LOCATION_TAG_THREAT_SCORE:
                return requestLocationTagTrends(draft, action.payload)

            case ActionType.RECEIVE_LOCATION_TAG_THREAT_SCORE:
                const {timeRange, scores} = action.payload

                switch (timeRange) {
                    case LAST_12H:
                        return receive12HourLocationTagTrendData(draft, scores)

                    case LAST_24H: {
                        const updatedTrends = receiveLocationTagTrends(draft.trendLast24H, scores)
                        if (updatedTrends === draft.trendLast24H && !draft.isFetchingLast24H) {
                            return draft
                        }

                        return {
                            ...draft,
                            isFetchingLast24H: false,
                            trendLast24H: updatedTrends,
                        }
                    }

                    case LAST_7D: {
                        const updatedTrends = receiveLocationTagTrends(draft.trendLast7D, scores)
                        if (updatedTrends === draft.trendLast7D && !draft.isFetchingLast7D) {
                            return draft
                        }

                        return {
                            ...draft,
                            isFetchingLast7D: false,
                            trendLast7D: updatedTrends,
                        }
                    }

                    case LAST_30D: {
                        const updatedTrends = receiveLocationTagTrends(draft.trendLast30D, scores)
                        if (updatedTrends === draft.trendLast30D && !draft.isFetchingLast30D) {
                            return draft
                        }
                        return {
                            ...draft,
                            isFetchingLast30D: false,
                            trendLast30D: updatedTrends,
                        }
                    }
                    case LAST_90D: {
                        const updatedTrends = receiveLocationTagTrends(draft.trendLast90D, scores)
                        if (updatedTrends === draft.trendLast90D && !draft.isFetchingLast90D) {
                            return draft
                        }
                        return {
                            ...draft,
                            isFetchingLast90D: false,
                            trendLast90D: updatedTrends,
                        }
                    }
                    default:
                        return draft
                }

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_LOCATION_TAG_THREAT_SCORE_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
