import ActionType from './action-type'
import {Action} from './actions'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {
    DEFAULT_LATEST_LOCATION_THREAT_SCORES_STATE,
    LatestLocationThreatScores,
    LatestLocationThreatScoresReduxState,
} from './state'
import {produce} from 'immer'

function areScoresEqual(
    obj1: LatestLocationThreatScores,
    obj2: LatestLocationThreatScores | undefined,
): boolean {
    if (!obj2) {
        return false
    }
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
        return false
    }

    // Check if scores for each location are the same
    for (const key of keys1) {
        // Check if the key exists in obj2
        if (!(key in obj2)) {
            return false
        }

        // Check if the scores are the same
        if (obj1[key].score !== obj2[key].score) {
            return false
        }
    }

    return true
}

export const latestLocationThreatScoresReducer = produce(
    (
        draft: LatestLocationThreatScoresReduxState = DEFAULT_LATEST_LOCATION_THREAT_SCORES_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_LATEST_LOCATION_THREAT_SCORES:
                draft.isFetchingLatestLocationThreatScores = true
                break

            case ActionType.RECEIVE_LATEST_LOCATION_THREAT_SCORES:
                draft.isFetchingLatestLocationThreatScores = false

                if (!areScoresEqual(draft.latestLocationThreatScores, action.payload)) {
                    draft.latestLocationThreatScores = action.payload
                }
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_LATEST_LOCATION_THREAT_SCORES_STATE
                break

            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
