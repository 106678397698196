import ActionType from './action-type'
import {Action} from './actions'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {
    DEFAULT_ACTIVE_LOCATION_STATE,
    DEFAULT_LOCATIONS_STATE,
    Location,
    LocationIdType,
    LocationMap,
    LocationsReduxState,
    Network,
} from './state'
import {produce} from 'immer'
import {getUpdatedDataArray} from '../reducer-helpers'
import moment from 'moment'
import {isEqual} from 'lodash'

const updateActiveLocation = (locations: LocationMap, newActiveLocation?: Location): Location => {
    if (
        !locations ||
        locations.size === 0 ||
        !newActiveLocation ||
        newActiveLocation.location === DEFAULT_ACTIVE_LOCATION_STATE.location
    ) {
        return DEFAULT_ACTIVE_LOCATION_STATE
    }

    const foundLocation = locations.get(newActiveLocation.location)
    return foundLocation ? foundLocation : DEFAULT_ACTIVE_LOCATION_STATE
}

function newLocationMap(locations: Location[]): LocationMap {
    const map = new Map<LocationIdType, Location>()
    locations.forEach((location) => map.set(location.location, location))
    return map
}

function getUpdatedLocationIndex(locations: Location[], updatedLocationId: LocationIdType): number {
    return locations.findIndex((location) => location.location === updatedLocationId)
}

const LocationsReducer = produce(
    (draft: LocationsReduxState = DEFAULT_LOCATIONS_STATE, action: Action | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_LOCATIONS:
                draft.isFetching = true
                if (draft.lastUpdate != null) {
                    draft.lastUpdate = null
                }
                break

            case ActionType.SET_ACTIVE_LOCATION:
                draft.activeLocation = updateActiveLocation(draft.locationMap, action.payload)
                break

            case ActionType.SET_LOCATIONS:
                const flagChanged = draft.isFetching !== false
                draft.isFetching = false
                const {dataUpdated, data} = getUpdatedDataArray<Location>(
                    action.payload,
                    draft.locations,
                    (a, b) => a.location === b.location,
                )
                if (dataUpdated) {
                    draft.locations = data
                    draft.locationMap = newLocationMap(data)
                    draft.activeLocation = updateActiveLocation(
                        draft.locationMap,
                        draft.activeLocation,
                    )
                }
                if (flagChanged || dataUpdated || !draft.lastUpdate) {
                    draft.lastUpdate = moment()
                }
                break

            case ActionType.UPDATE_VESSEL_CONFIG:
                const updatedLocationIndex = getUpdatedLocationIndex(
                    draft.locations,
                    action.payload.locationId,
                )
                const selectedLocation = draft.locations[updatedLocationIndex]

                const selectedMapLocation = draft.locationMap.get(selectedLocation.location)!

                selectedLocation.type = action.payload.type
                selectedMapLocation.type = action.payload.type
                selectedLocation.customerLocationCode = action.payload.customerLocationCode
                selectedMapLocation.customerLocationCode = action.payload.customerLocationCode
                if (!isEqual(selectedLocation.metaData, action.payload.metaData)) {
                    selectedLocation.metaData = action.payload.metaData
                    selectedMapLocation.metaData = action.payload.metaData
                }
                if (
                    !isEqual(
                        selectedLocation.reportConfiguration,
                        action.payload.reportConfiguration,
                    )
                ) {
                    selectedLocation.reportConfiguration = action.payload.reportConfiguration
                    selectedMapLocation.reportConfiguration = action.payload.reportConfiguration
                }
                break
            case ActionType.CHANGE_VESSEL_EMAIL: {
                draft.updatedEmailLocation = action.payload
                break
            }
            case ActionType.CHANGE_BUSINESS_NETWORKS:
                if (!draft.updatedNetworkDataObject) {
                    draft.updatedNetworkDataObject = {} as Network
                }
                if (!isEqual(draft.updatedNetworkDataObject?.BusinessNetworks, action.payload)) {
                    draft.updatedNetworkDataObject.BusinessNetworks = action.payload
                        ?.filter((e) => e.trim().length !== 0)
                        .map((e) => e.trim())
                }
                break
            case ActionType.CHANGE_OT_NETWORKS:
                if (!draft.updatedNetworkDataObject) {
                    draft.updatedNetworkDataObject = {} as Network
                }
                if (!isEqual(draft.updatedNetworkDataObject?.OTNetworks, action.payload)) {
                    draft.updatedNetworkDataObject.OTNetworks = action.payload
                        ?.filter((e) => e.trim().length !== 0)
                        .map((e) => e.trim())
                }
                break
            case ActionType.CHANGE_CREW_NETWORKS:
                if (!draft.updatedNetworkDataObject) {
                    draft.updatedNetworkDataObject = {} as Network
                }

                if (!isEqual(draft.updatedNetworkDataObject?.CrewNetworks, action.payload)) {
                    draft.updatedNetworkDataObject.CrewNetworks = action.payload
                        ?.filter((e) => e.trim().length !== 0)
                        .map((e) => e.trim())
                }
                break
            case ActionType.CHANGE_DASHBOARD_AGENT_IDS:
                draft.updatedvesselDashboardAgentIds = [action.payload]
                break
            case ActionType.UPDATE_VESSEL_NETWORK_CONFIG:
                draft.updatedNetworkDataObject = action.payload
                break
            case ActionType.UPDATE_VESSEL_DASHBOARD_AGENT_IDS:
                draft.updatedvesselDashboardAgentIds = action.payload
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_LOCATIONS_STATE
                break

            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)

export default LocationsReducer
